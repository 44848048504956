<template>
  <div>
    <div class="m-5">
      <el-card class="profile">
        <el-descriptions title="Entry Result" :column="24">
          <el-descriptions-item label="Score" :span="8">{{ totalScore }}</el-descriptions-item>
          <el-descriptions-item label="Award" :span="16">{{ showAward }}</el-descriptions-item>
          <el-descriptions-item label="Comment" :span="16">{{ comment }}</el-descriptions-item>
        </el-descriptions>
      </el-card>
    </div>
  </div>
</template>

<script>
import {getReviewEntryV1} from '../../api/eae'

export default {
  name: "index",
  data() {
    return {
      entry_id: this.$route.query.id,
      entryInfo: {},
      totalScore: 0,
      showAward: '',
      comment: ''
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      getReviewEntryV1(this.entry_id).then((res) => {
        let result = res.data.data
        let entryScore = 0
        let bonus_points = 0;
        bonus_points = result.score.bonus_points
        let scoring_dimension = result.score.scoring_dimension
        let keys = Object.keys(scoring_dimension)
        for (let i = 0; i < keys.length; i++) {
          let score = scoring_dimension[keys[i]]
          entryScore = entryScore + parseInt(score)
        }
        this.comment = result.score.comment
        this.totalScore = (entryScore + bonus_points)
        this.showAward = this.formatAward(this.totalScore)
      })
    },
    formatAward(score) {
      if (score >= 75) {
        return 'Superior Award'
      }
      if (score >= 65) {
        return 'Distinctive Award'
      }
      if (score >= 50) {
        return 'Excellent Award'
      }
      return 'Extracurricular Activity Recognition'
    },
  }
}
</script>

<style scoped>

</style>
